<template>
  <div id="astropayDeposit">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t('deposit.astropay.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/astropay.png" alt="" />
              <p>{{ $t('deposit.astropay.desc') }}</p>
              <ul>
                <li>{{ $t('deposit.bitwallet.inst1') }}</li>
                <li>{{ $t('deposit.astropay.inst2') }}</li>
                <li>{{ $t('deposit.astropay.inst3') }}</li>
                <li>{{ $t('deposit.astropay.inst4') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.astropay.form.header') }}</p>
              <el-form label-position="top" :model="astropayForm" ref="astropayForm" status-icon :rules="astropayRules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        :supportedCurrenciesList="supportedCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="astropayForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                        <el-input v-model="astropayForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import { apiAstropay } from '@/resource';

export default {
  name: 'AstropayDeposit',
  components: { NumericInput, AccountNumber },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      astropayForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      astropayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      supportedCurrencies: ['USD', 'AUD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD']
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.astropayForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiAstropay(
        {
          mt4Account: this.astropayForm.accountNumber,
          operateAmount: this.astropayForm.amount,
          applicationNotes: this.astropayForm.notes
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['astropayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                window.location = result.data.msg;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
